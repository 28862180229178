import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from './navbar/navbar.component';
import {FooterComponent} from './footer/footer.component';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {AvatarModule} from 'ngx-avatar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    AvatarModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule, 
    PdfViewerModule    
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    ConfirmDialogComponent
    
                
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    ConfirmDialogComponent
  ]
})
export class ComponentsModule {
}
